<template>
    <!-- 查看优惠劵订单 -->
    <div class="box-card" style="text-align: left; margin-top:0;">
      <el-form label-width="120px" class="left_box">
        <div class="title">订单信息</div>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="订单号：">{{detailForm.order_no}}</el-form-item>
            <el-form-item label="支付方式：">{{ detailForm.order_price ? getWayName('PaymentMethod',detailForm.payment_method) : '无'}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="订单金额：">{{detailForm.order_price ? '￥' + detailForm.order_price.toFixed(2) : '￥0.00' }}</el-form-item>
            <el-form-item label="支付时间：">{{$moment(detailForm.payment_time).format("YYYY-MM-DD HH:mm:ss") }}</el-form-item>
          </el-col>
        </el-row>
        <div class="title">商家信息</div>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="商家号：">{{detailForm.merchant_number}}</el-form-item>
            <el-form-item label="负责人：">{{detailForm.principal}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商家名称：">{{detailForm.merchant_name}}</el-form-item>
            <el-form-item label="手机号：">{{detailForm.phone}}</el-form-item>
          </el-col>
        </el-row>
        <div class="title">套餐包</div>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="停车场：">{{detailForm.parking_name}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="续费套餐：">{{detailForm.coupon_package_name}}</el-form-item>
          </el-col>
        </el-row>
        <el-table class="tablestyle" :data="detailForm.coupons" tooltip-effect="dark" style="width: 100%" border stripe>
          <el-table-column type="index" label="序号" width="100"></el-table-column>
          <el-table-column prop="coupon_name" label="优惠劵名称"></el-table-column>
          <el-table-column label="优惠劵类型">
            <template slot-scope="scope">{{getWayName('CouponType', scope.row.coupon_type)}}</template>
          </el-table-column>
          <el-table-column prop="coupon_count" label="张数"></el-table-column>
        </el-table>
      </el-form>
    </div>
  </template>
<script>
import { getCouponsOrderPageList, getCouponsOrderDetail } from '@/api/discountCoupon'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      detailForm: {}
    }
  },
  created () {
    this.fngetCouponsOrderDetail()
  },
  mounted () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getOptions', 'getWayName', 'getAllParkingList'])
  },
  watch: {},
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 返回
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    },
    async fngetCouponsOrderDetail () {
      const res = await getCouponsOrderDetail({ _id: this.id })
      console.log(res)
      this.detailForm = res && res.Code === 200 ? res.Data : {}
    }
  }
}
</script>
  <style scoped lang="less">
  .title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    color: #000;
  }
  .left_box {
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .tablestyle {
    /deep/ .el-table__body-wrapper {
      max-height: 220px;
    }
  }
  </style>
